.signInForm {
    width: 316px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.signInForm .resetPassword {
    /* color: #8A8F9C; */
    color: #E00000;
    user-select: none;
}

.signInForm .resetPassword:hover {
    color: #E00000;
    cursor: pointer;
}


.signInForm input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
}