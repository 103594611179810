.bodyWrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto 100px auto;
  width: 100%;
  padding: 0 10px;
}
.maim-body {
  flex: 0 0 calc(100% - 200px);
  max-width: calc(100% - 200px);
  padding-left: 20px;
}
.header {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerLogo {
  width: 200px;
  display: flex;
  height: 120px;
  cursor: pointer;
}

.headerLogo img {
  width: 210px;
  margin: auto;
}
