a {
    color: #DF0B12;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #DF0B12;
    text-decoration: underline;
    background-color: transparent;
}
.inspection{
    /*
    width: 195px;
    height: 510px;
    margin: 0 10px 20px 0;
    */

    width: 260px;
    height: 510px;
    margin: 0 10px 160px 0;
}

.inspection p{
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.inspection p.inspectionTitle{
    font-weight: 450;
    font-size: 12px;    
    text-align: left;
    color: #262626;
    margin-bottom: 10px;
}

.info-wrapper {
    flex-direction: row;
    display: flex;
}

.inspection p.inspectionName{
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #454f63;
    min-height: 35px;
    margin-top: 4px;
}

.inspection p.inspectionPhone, .inspection p.inspectionEmail{
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #7b7f86;
    min-height: 19px;
}

.inspection p.inspectionStatus{
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #8a8f9c;
    margin: 5px 0;
}

.inspection p.inspectionDate, .inspection p.inspectionVin{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #262626;
    min-height: 22px;
}

.inspection div.inspectionLine{
    width: 100%;
    border-top: 1px solid #F4F4F6;
    margin: 5px 0;
}

.inspection div.inspectionSendMail{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #000000;
    user-select: none;
}

.inspection div.inspectionSendMail.active:hover{
    color: #717683;
    cursor: pointer;
}

.inspection > p, .inspectionWrapper > p{
    display: flex;
    align-items: center;
}

.inspection p > svg, .inspection div.inspectionSendMail svg{
    margin-right: 5px;
}

.inspectionWrapper{
    position: relative;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    height: 620px;
}

.carInfo{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0;
}
.carInfoRow1{
    height: 75px;
}

.carInfo svg{
    margin-top: 3px;
}

.carInfo > div{
    background: #454F63;
    /*width: 30%;*/
    width:32%;
    border-radius: 8px;
    color: white;
    overflow: hidden;
    padding: 3px;
    min-height: 57px;
}

.carInfo p.carPlate{
    border-radius: 10px;
    background: transparent;
    /*border: 1px solid #586079;*/
    /*padding: 2px 5px;*/
    /*margin: 3px 0 3px 0;*/
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    margin-top: 0;
}

.carInfo p.shortString{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carInfo div > p{
    text-align: center;
    margin: 5px 0;
    font-size: 12px;
}

.carInfo div > p:first-child{
    font-weight: normal;
    color: #959dad;
}

.carInfo div > p:last-child{
    font-weight: 600;
    letter-spacing: 0.02em;
    color: #fff;
}

.carImages .slide{
    overflow: hidden;
    border-radius: 4px;
    height: 180px;
}

.defaultImage{
    border-radius: 4px;

}
img.defaultImage {
    width: 100% !important;
}
.carousel-item.active {
    display: flex;
    height: 180px;
    z-index: 2;

}
.carousel-item {
    display: none;
    height: 180px;
    z-index: 1;
}

.carImages img{
    /*
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    */

    /* style for car logo brand */        
    margin: auto;
    max-width: 240px;
    max-height: 180px;
    z-index: 1
}

.inspectionType{
    width: 80%;
    height: 40px;
    border-radius: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;

}

.inspectionType p{
    display: flex;
    align-items: center;
    user-select: none;
    text-transform: uppercase;
}

.inspectionType p path{
    fill: #FFFFFF;
}

.inspectionType{
    background-color: #b9b9b9;
    color: #FFFFFF;
}

.inspectionType.Advanced{
    background-color: #DC0000;
}

.inspectionType.Basic{
    background-color: #454F63;
}

ol.carousel-indicators{
    margin-bottom: 7px;
}

ol.carousel-indicators li{
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    opacity: 1;
    background: #000;    
    border: 1px solid #fff;
    border-radius: 10px;
}

ol.carousel-indicators li.active{
    background: #E00000;
}

.inspection-edit-button{
    position: absolute;
    /* before delete button underneath edit
    top: -10px;
    right: -5px;
    */
    top: -10px;
    right: 35px;


    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    background: #1B1D1B;
    border-radius: 50%;
    color: #FFFFFF;
}

.inspection-edit-button:hover{
    cursor: pointer;
    background: #c70000;
}

.inspection-delete-button:hover{
    cursor: pointer;
    background: #c70000;
}
.inspection-delete-button{
    position: absolute;
    top: -10px;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    background: #e00000;
    border-radius: 50%;
    color: #FFFFFF;
}

.customer-info{
    cursor: help;
}

.customer-info i{
    margin: 5px 2px 0 0;
}

.customer-info:hover .customer-info-notification{
    display: block;
}

.customer-info-notification{
    display: none;
    background-color: #FFFFFF;
    position: absolute;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 3px 10px #454F63;
    width: 180px;
    z-index: 22;
}

.customer-info-notification .inspectionName{
    font-size: 14px !important;
    min-height: 22px !important;
}
.distanceUnit{
    text-transform: uppercase;
}