@import url('https://fonts.googleapis.com/css?family=Rubik:400,500');

html {
    font-family: 'Rubik', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Rubik', sans-serif;
    overflow-y: scroll;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.v-middle{
    vertical-align: middle !important;
}
.ag-theme-balham .ag-paging-panel {
    border-top: 0px solid #BDC3C7 !important;
    color: rgba(0, 0, 0, 0.54);
    height: 32px;
    padding: 0 12px;
}
.ag-theme-balham .ag-root {
    border: 0px solid #BDC3C7 !important;
    border-top: 1px solid #BDC3C7;
}
.ag-theme-balham .ag-header-row {
    border: 1px solid #BDC3C7 !important;
    height: 32px;
}
.ag-body-viewport .ag-center-cols-container {
    display: block;    
}
.ag-theme-balham .ag-header {
    border-bottom: 0px solid #BDC3C7 !important;
}
.ag-body-viewport .ag-center-cols-container{
    border: 1px solid #BDC3C7 !important;
}
.ag-theme-balham .ag-row-odd {
    background-color: transparent !important;
}
.ag-theme-balham .ag-row-even {
    background-color: transparent !important;
}
button.logoutBtn.btn.btn-secondary {
    border: 0px;
}

ag-root-wrapper .ag-watermark {
    position: none !important;
}
.ag-root-wrapper .ag-watermark {
    /* position: absolute; */
    bottom: none !important;
    right: none !important;
    opacity: 0 !important;
    -webkit-transition: none !important;
    transition: none !important;
}