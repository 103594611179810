.entry-dropdown {
  display: flex !important;
  font-size: 13px !important;
  align-items: center !important;
  font-weight: 500;
}
.entry-dropdown select {
  width: 80px !important;
  height: 24px;
}
.icon-btn {
  padding: 0;
  background: transparent;
  border: 0;
}
.ag-header-cell-label .ag-header-cell-text {
  text-overflow: unset !important;
  overflow: visible;
}
.ag-theme-balham .ag-header-cell {
  padding-left: 4px;
  padding-right: 4px;
}
.ag-center-cols-clipper .ag-cell {
  text-overflow: unset !important;
  padding-left: 4px;
  padding-right: 4px;
}

.ag-header-icon span{background-position: center;background-size: 100%;background-repeat: no-repeat;}
.ag-header-icon span::before{opacity: 0;}
.ag-header-icon.ag-sort-ascending-icon span{background-image: url(../../../assets/images/icons/sort-up.svg);}
.ag-header-icon.ag-sort-descending-icon span{background-image: url(../../../assets/images/icons/sort-down.svg);}
.ag-header-icon.ag-sort-none-icon span{background-image: url(../../../assets/images/icons/sort-up-and-down.svg);}
.ag-header-container,
.ag-header-row,
.ag-center-cols-container{min-width:100% !important}
.ag-theme-balham .ag-filter .ag-set-filter-list{
  height:fit-content
}
.ag-root {
  animation: ag-root 0.2s;
 }
 @keyframes ag-root {
  from {
    padding-right: 20px;
  }
  to {
    padding-right: 0px;
  }
 }