@import url(https://fonts.googleapis.com/css?family=Rubik:400,500);
html {
    font-family: 'Rubik', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Rubik', sans-serif;
    overflow-y: scroll;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.v-middle{
    vertical-align: middle !important;
}
.ag-theme-balham .ag-paging-panel {
    border-top: 0px solid #BDC3C7 !important;
    color: rgba(0, 0, 0, 0.54);
    height: 32px;
    padding: 0 12px;
}
.ag-theme-balham .ag-root {
    border: 0px solid #BDC3C7 !important;
    border-top: 1px solid #BDC3C7;
}
.ag-theme-balham .ag-header-row {
    border: 1px solid #BDC3C7 !important;
    height: 32px;
}
.ag-body-viewport .ag-center-cols-container {
    display: block;    
}
.ag-theme-balham .ag-header {
    border-bottom: 0px solid #BDC3C7 !important;
}
.ag-body-viewport .ag-center-cols-container{
    border: 1px solid #BDC3C7 !important;
}
.ag-theme-balham .ag-row-odd {
    background-color: transparent !important;
}
.ag-theme-balham .ag-row-even {
    background-color: transparent !important;
}
button.logoutBtn.btn.btn-secondary {
    border: 0px;
}

ag-root-wrapper .ag-watermark {
    position: none !important;
}
.ag-root-wrapper .ag-watermark {
    /* position: absolute; */
    bottom: none !important;
    right: none !important;
    opacity: 0 !important;
    -webkit-transition: none !important;
    transition: none !important;
}
body{
    background-color: #F5F6F7;
}

.landing-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: transparent;
    padding-bottom: 25px;
    /*border-bottom: 1px solid #eee;*/
    margin-bottom: 20px;
    margin-top: 50px;
}

.landing-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
}

.landing-wrapper p:first-of-type{
    color: #59626A;
    width: 80%;
    height: 46px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.landing-wrapper p:last-of-type{
    width: 90%;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #168eea;
    cursor: pointer;
}

.landing-wrapper h3{
    margin: 0 0 15px 0;
    font-weight: 600;
    font-size: 25px;
    text-align: left;
    color: #262626;
}

.landing-container img{
    text-align: center;
}

.signInForm button{
    height: 50px;
    background: #e00000;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.02));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.02));
}

.signInForm input{
    height: 50px;
    border-radius: 6px;
    background: #fff;
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
}

.signInForm label{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
}

.signInForm p{
    color: #59626A;
    width: 100%;
    height: 46px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    text-align: center;
}
.confirmBookingModal {
  margin-top: 13%;
}

.confirmBookingModal .modal-content {
  background-color: #2a2e43;
  border: none;
  border-radius: 12px;
}

.confirmBookingModal .modal-header {
  border: none;
}

.confirmBookingModal h5.modal-title {
  font-weight: 600;
  font-size: 24px;
  text-align: left;
  color: #fff;
}

.confirmBookingModal .modal-header button span {
  color: #ffffff;
  text-shadow: none;
}

.confirmBookingModal .modal-header button {
  outline: none;
}

.confirmBookingModal .modal-body {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #fff;
  opacity: 0.68;
}

.confirmBookingModal .modal-body p {
  margin: 0;
}

.confirmBookingModal .modal-footer {
  border: none;
  -webkit-justify-content: center;
          justify-content: center;
}

.confirmBookingModal .modalButton {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #fd0000;
  opacity: 0.9;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  color: #ffffff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.confirmBookingModal .modalButton:hover {
  opacity: 1;
}

.confirmBookingModal .modalButton span {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.confirmBookingModal .modalButton.accept {
  background: #00fa87;
}

.confirmBookingModal.router .decline {
  display: none;
}
.showBtn {
  min-width: 106px;
  padding: 14px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 12px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.showBtn:hover {
  opacity: 0.7;
}

.showAcceptBtn {
  background: #fff;
  opacity: 0.9;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  color: #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.showDeclineBtn {
  opacity: 0.9;
  color: #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  background: #e00000;
  color: #fff;
}

.signInForm {
    width: 316px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: auto;
}

.signInForm .resetPassword {
    /* color: #8A8F9C; */
    color: #E00000;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.signInForm .resetPassword:hover {
    color: #E00000;
    cursor: pointer;
}


.signInForm input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
.bodyWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto 100px auto;
  width: 100%;
  padding: 0 10px;
}
.maim-body {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: calc(100% - 200px);
          flex-basis: calc(100% - 200px);
  max-width: calc(100% - 200px);
  padding-left: 20px;
}
.header {
  margin: 10px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.headerLogo {
  width: 200px;
  display: -webkit-flex;
  display: flex;
  height: 120px;
  cursor: pointer;
}

.headerLogo img {
  width: 210px;
  margin: auto;
}

.navBar {
  display: -webkit-flex;
  display: flex;
  margin: 20px 0 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 0 0 200px;
          flex: 0 0 200px;
  max-width: 200px;
}

.userWrapper {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-bottom: 10px;
}

.userWrapper p {
  margin: 0;
  padding: 0;
}

.userWrapper > p:first-of-type {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  color: #262626;
}

.userWrapper > p:last-of-type {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #8a8f9c;
}

.inspections {
  margin: 20px 0 8px 0;
}

.inspections > div {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.inspections > div svg {
  margin-right: 7px;
}

.inspections div.active {
  color: #e00000;
}

.inspections div.disabled {
  color: #8d929e;
}

.inspections div.disabled:hover {
  color: #428bca;
}

button.bookInspectionButton {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 38px;
  background: #e00000;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
}
button.logoutBtn {
  background: #e00000;
}

button.bookInspectionButton svg {
  margin-right: 7px;
}

.inspections .title {
  font-weight: 600;
  text-align: left;
  color: #8a8f9c;
  margin: 0 0 10px 0;
}

.inspections > .filter {
  margin-left: 10px;
}

.settingsButton {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  color: #8a8f9c;
  margin-bottom: 15px;
}

.settingsButton.active {
  color: #e00000;
}

.settingsButton.active svg path {
  fill: #e00000;
}

.settingsButton:hover {
  color: #e00000;
  cursor: pointer;
}

.settingsButton:hover svg path {
  fill: #e00000;
}

.settingsButton svg {
  margin-right: 7px;
}

.support {
  margin-bottom: 20px;
}

.support h6 {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  color: #8a8f9c;
}

.support a {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  color: #8a8f9c;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 5px;
}

.support a:hover {
  color: #e00000;
}

.support a svg {
  margin: 0 10px;
}

.userIcon{
    display: -webkit-flex;
    display: flex;
    width: 50px;
    height: 50px;
    background-color: #1B1D1B;
    border-radius: 12px;
    overflow: hidden;
    color: #ffffff;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin-bottom: 10px;
}

.userIcon p{
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-ellipsis div {
    position: absolute;
    top: 42px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #454F63;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 6px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 6px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 26px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 45px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes lds-ellipsis1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@-webkit-keyframes lds-ellipsis3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}
@-webkit-keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(19px, 0);
                transform: translate(19px, 0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(19px, 0);
                transform: translate(19px, 0);
    }
}
#booking {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#booking h1 {
  font-weight: normal;
  font-size: 40px;
  text-align: left;
  color: #262626;
  margin-bottom: 5px;
}

#booking > p {
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: #262626;
}

#booking .search-icon {
  width: 36px;
  height: 35px;
  background: #e00000;
  -webkit-filter: drop-shadow(0px 12px 16px rgba(69, 91, 99, 0.1));
          filter: drop-shadow(0px 12px 16px rgba(69, 91, 99, 0.1));
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 10px;
}

#booking .search-btn {
  /* width: 36px;
    height: 35px; */
  background: #e00000;
  -webkit-filter: drop-shadow(0px 12px 16px rgba(69, 91, 99, 0.1));
          filter: drop-shadow(0px 12px 16px rgba(69, 91, 99, 0.1));
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  /* align-items: center;
    margin-right: 10px; */
}

#booking .search-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: normal;
  font-size: 15px;
  text-align: left;
  color: #262626;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 200px;
}

#booking .search-button:hover {
  cursor: pointer;
}

#booking .search-button:hover .search-icon {
  background-color: #ce0000;
}

#booking .search-bar {
  margin: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  /* flex-wrap: wrap;
    align-items: start; */
  -webkit-align-items: end;
          align-items: end;
  /*background: #eee;*/
  padding: 5px;
  width: 50%;
}
.evaluation {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.dropdown-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#booking .search-bar input {
  height: 35px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
  border: none;
  padding: 5px;
}

#booking .search-bar button.search {
  height: 48px;
  margin: 7px 0 0 0;
  background: #e00000;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
  border: none;
  width: 22%;
}

#booking .search-bar button.search:hover {
  background: #cc0000;
}

#booking .search-bar input.middle {
  max-width: 49%;
}
#booking .search-bar input.search {
  max-width: 100%;
}

#booking .search-bar input.small {
  /*max-width: 25%;*/
  max-width: 32.4%;
}

#booking .react-datepicker-wrapper {
  width: 24%;
}

#booking .react-datepicker-wrapper input,
#booking .react-datepicker-wrapper .react-datepicker__input-container {
  width: 99%;
}

#booking .react-datepicker-wrapper input {
  padding: 6px 12px;
}

#booking .intl-tel-input {
  width: 25%;
  margin: 7px 7px 0 0;
}

#booking .flag-container {
  padding: 0;
}

#booking .selected-flag {
  background-color: #ffffff !important;
}

.ais-Hits-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}

.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.3rem 1.7rem;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
}

.ais-SearchBox-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  color: "red";
}
.ais-SearchBox-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  color: "red";
}

.ais-InstantSearch__root {
  width: 100%;
}

.grid{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
}

.loaderWrapper{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
}
a {
    color: #DF0B12;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #DF0B12;
    text-decoration: underline;
    background-color: transparent;
}
.inspection{
    /*
    width: 195px;
    height: 510px;
    margin: 0 10px 20px 0;
    */

    width: 260px;
    height: 510px;
    margin: 0 10px 160px 0;
}

.inspection p{
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.inspection p.inspectionTitle{
    font-weight: 450;
    font-size: 12px;    
    text-align: left;
    color: #262626;
    margin-bottom: 10px;
}

.info-wrapper {
    -webkit-flex-direction: row;
            flex-direction: row;
    display: -webkit-flex;
    display: flex;
}

.inspection p.inspectionName{
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #454f63;
    min-height: 35px;
    margin-top: 4px;
}

.inspection p.inspectionPhone, .inspection p.inspectionEmail{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #7b7f86;
    min-height: 19px;
}

.inspection p.inspectionStatus{
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #8a8f9c;
    margin: 5px 0;
}

.inspection p.inspectionDate, .inspection p.inspectionVin{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #262626;
    min-height: 22px;
}

.inspection div.inspectionLine{
    width: 100%;
    border-top: 1px solid #F4F4F6;
    margin: 5px 0;
}

.inspection div.inspectionSendMail{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #000000;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.inspection div.inspectionSendMail.active:hover{
    color: #717683;
    cursor: pointer;
}

.inspection > p, .inspectionWrapper > p{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.inspection p > svg, .inspection div.inspectionSendMail svg{
    margin-right: 5px;
}

.inspectionWrapper{
    position: relative;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    height: 620px;
}

.carInfo{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 10px 0;
}
.carInfoRow1{
    height: 75px;
}

.carInfo svg{
    margin-top: 3px;
}

.carInfo > div{
    background: #454F63;
    /*width: 30%;*/
    width:32%;
    border-radius: 8px;
    color: white;
    overflow: hidden;
    padding: 3px;
    min-height: 57px;
}

.carInfo p.carPlate{
    border-radius: 10px;
    background: transparent;
    /*border: 1px solid #586079;*/
    /*padding: 2px 5px;*/
    /*margin: 3px 0 3px 0;*/
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    margin-top: 0;
}

.carInfo p.shortString{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carInfo div > p{
    text-align: center;
    margin: 5px 0;
    font-size: 12px;
}

.carInfo div > p:first-child{
    font-weight: normal;
    color: #959dad;
}

.carInfo div > p:last-child{
    font-weight: 600;
    letter-spacing: 0.02em;
    color: #fff;
}

.carImages .slide{
    overflow: hidden;
    border-radius: 4px;
    height: 180px;
}

.defaultImage{
    border-radius: 4px;

}
img.defaultImage {
    width: 100% !important;
}
.carousel-item.active {
    display: -webkit-flex;
    display: flex;
    height: 180px;
    z-index: 2;

}
.carousel-item {
    display: none;
    height: 180px;
    z-index: 1;
}

.carImages img{
    /*
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    */

    /* style for car logo brand */        
    margin: auto;
    max-width: 240px;
    max-height: 180px;
    z-index: 1
}

.inspectionType{
    width: 80%;
    height: 40px;
    border-radius: 12px;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 10px auto;

}

.inspectionType p{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-transform: uppercase;
}

.inspectionType p path{
    fill: #FFFFFF;
}

.inspectionType{
    background-color: #b9b9b9;
    color: #FFFFFF;
}

.inspectionType.Advanced{
    background-color: #DC0000;
}

.inspectionType.Basic{
    background-color: #454F63;
}

ol.carousel-indicators{
    margin-bottom: 7px;
}

ol.carousel-indicators li{
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    opacity: 1;
    background: #000;    
    border: 1px solid #fff;
    border-radius: 10px;
}

ol.carousel-indicators li.active{
    background: #E00000;
}

.inspection-edit-button{
    position: absolute;
    /* before delete button underneath edit
    top: -10px;
    right: -5px;
    */
    top: -10px;
    right: 35px;


    display: -webkit-flex;


    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 26px;
    height: 26px;
    background: #1B1D1B;
    border-radius: 50%;
    color: #FFFFFF;
}

.inspection-edit-button:hover{
    cursor: pointer;
    background: #c70000;
}

.inspection-delete-button:hover{
    cursor: pointer;
    background: #c70000;
}
.inspection-delete-button{
    position: absolute;
    top: -10px;
    right: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 26px;
    height: 26px;
    background: #e00000;
    border-radius: 50%;
    color: #FFFFFF;
}

.customer-info{
    cursor: help;
}

.customer-info i{
    margin: 5px 2px 0 0;
}

.customer-info:hover .customer-info-notification{
    display: block;
}

.customer-info-notification{
    display: none;
    background-color: #FFFFFF;
    position: absolute;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 3px 10px #454F63;
    width: 180px;
    z-index: 22;
}

.customer-info-notification .inspectionName{
    font-size: 14px !important;
    min-height: 22px !important;
}
.distanceUnit{
    text-transform: uppercase;
}
.confirmBookingModal {
  margin-top: 13%;
}

.confirmBookingModal .modal-content {
  background-color: #2a2e43;
  border: none;
  border-radius: 12px;
}

.confirmBookingModal .modal-header {
  border: none;
}

.confirmBookingModal h5.modal-title {
  font-weight: 600;
  font-size: 24px;
  text-align: left;
  color: #fff;
}

.confirmBookingModal .modal-header button span {
  color: #ffffff;
  text-shadow: none;
}

.confirmBookingModal .modal-header button {
  outline: none;
}

.confirmBookingModal .modal-body {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #fff;
  opacity: 0.68;
}

.confirmBookingModal .modal-body p {
  margin: 0;
}

.confirmBookingModal .modal-footer {
  border: none;
  -webkit-justify-content: center;
          justify-content: center;
}

.confirmBookingModal .modalButton {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #fd0000;
  opacity: 0.9;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  color: #ffffff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.confirmBookingModal .modalButton:hover {
  opacity: 1;
}

.confirmBookingModal .modalButton span {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.confirmBookingModal .modalButton.accept {
  background: #00fa87;
}

.confirmBookingModal.router .decline {
  display: none;
}
.showBtn {
  min-width: 106px;
  padding: 14px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 12px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.showBtn:hover {
  opacity: 0.7;
}

.showAcceptBtn {
  background: #fff;
  opacity: 0.9;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  color: #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.showDeclineBtn {
  opacity: 0.9;
  color: #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  background: #e00000;
  color: #fff;
}

.m-loader .modal-content{
  background: transparent;
  border: 0;
}

.m-loader .modal-content .lds-roller{ 
margin: auto
}
.lds-roller {
display: inline-block;
position: relative;
width: 64px;
height: 64px;
}
.lds-roller div {
-webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
-webkit-transform-origin: 32px 32px;
        transform-origin: 32px 32px;
}
.lds-roller div:after {
content: " ";
display: block;
position: absolute;
width: 6px;
height: 6px;
border-radius: 50%;
background: #fff;
margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
-webkit-animation-delay: -0.036s;
        animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
top: 50px;
left: 50px;
}
.lds-roller div:nth-child(2) {
-webkit-animation-delay: -0.072s;
        animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
top: 54px;
left: 45px;
}
.lds-roller div:nth-child(3) {
-webkit-animation-delay: -0.108s;
        animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
top: 57px;
left: 39px;
}
.lds-roller div:nth-child(4) {
-webkit-animation-delay: -0.144s;
        animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
top: 58px;
left: 32px;
}
.lds-roller div:nth-child(5) {
-webkit-animation-delay: -0.18s;
        animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
top: 57px;
left: 25px;
}
.lds-roller div:nth-child(6) {
-webkit-animation-delay: -0.216s;
        animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
top: 54px;
left: 19px;
}
.lds-roller div:nth-child(7) {
-webkit-animation-delay: -0.252s;
        animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
top: 50px;
left: 14px;
}
.lds-roller div:nth-child(8) {
-webkit-animation-delay: -0.288s;
        animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
top: 45px;
left: 10px;
}
@-webkit-keyframes lds-roller {
0% {
-webkit-transform: rotate(0deg);
        transform: rotate(0deg);
}
100% {
-webkit-transform: rotate(360deg);
        transform: rotate(360deg);
}
}
@keyframes lds-roller {
0% {
-webkit-transform: rotate(0deg);
        transform: rotate(0deg);
}
100% {
-webkit-transform: rotate(360deg);
        transform: rotate(360deg);
}
}

.date-selector{
    /* margin-right: 10px; */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.date-selector input{
    height: 38px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
    border: none;
    min-height: 0;
}

.date-selector button.dropdown-toggle{
    background-color: #E00000;
}

.date-selector button.dropdown-item.active{
    background-color: #6c757d;
    color: #FFFFFF;
}
.date-selector button.dropdown-item:active{
}

#booking .date-selector .react-datepicker-wrapper{
    width: 100%;
}
.entry-dropdown {
  display: -webkit-flex !important;
  display: flex !important;
  font-size: 13px !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  font-weight: 500;
}
.entry-dropdown select {
  width: 80px !important;
  height: 24px;
}
.icon-btn {
  padding: 0;
  background: transparent;
  border: 0;
}
.ag-header-cell-label .ag-header-cell-text {
  text-overflow: unset !important;
  overflow: visible;
}
.ag-theme-balham .ag-header-cell {
  padding-left: 4px;
  padding-right: 4px;
}
.ag-center-cols-clipper .ag-cell {
  text-overflow: unset !important;
  padding-left: 4px;
  padding-right: 4px;
}

.ag-header-icon span{background-position: center;background-size: 100%;background-repeat: no-repeat;}
.ag-header-icon span::before{opacity: 0;}
.ag-header-icon.ag-sort-ascending-icon span{background-image: url(/static/media/sort-up.ad9ef7c3.svg);}
.ag-header-icon.ag-sort-descending-icon span{background-image: url(/static/media/sort-down.704851ab.svg);}
.ag-header-icon.ag-sort-none-icon span{background-image: url(/static/media/sort-up-and-down.1154b49e.svg);}
.ag-header-container,
.ag-header-row,
.ag-center-cols-container{min-width:100% !important}
.ag-theme-balham .ag-filter .ag-set-filter-list{
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content
}
.ag-root {
  -webkit-animation: ag-root 0.2s;
          animation: ag-root 0.2s;
 }
 @-webkit-keyframes ag-root {
  from {
    padding-right: 20px;
  }
  to {
    padding-right: 0px;
  }
 }
 @keyframes ag-root {
  from {
    padding-right: 20px;
  }
  to {
    padding-right: 0px;
  }
 }
#createPage > h1{
    font-weight: normal;
    font-size: 40px;
    text-align: left;
    color: #262626;
}

#createPage > p{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #168eea;
}

.createInspection{
    max-width: 800px;
    margin: auto;
    background-color: #F5F6F7;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.createInspectionButton{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.createInspectionButton button{
    height: 48px;
    width: 117px;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
}

.createInspectionButton button:first-of-type{
    background: #282828;
}

.createInspectionButton button:last-of-type{
    background: #e00000;
}

.createInspection input, .createInspection select{
    border: none;
}

.createInspectionHeader{
    display: -webkit-flex;
    display: flex;
    padding: 25px;
    width: 100%;
    background-color: #FFFFFF;
}

.createInspectionHeader h3{
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #262626;
}

.createInspectionHeader p{
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #59626a;
}

.createInspectionHeader svg{
    margin-right: 10px;
}

.createInspectionBody{
    margin: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.inp-select{
    max-width: 216px;
}

.error {
    font-size: 10px;
    color: #DD0E0E;
    margin-top: 10px;
    font-weight: 500;
}

.tutorial{
    max-width: 510px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin: 15px auto;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.tutorial-line{
    position: relative;
    border: 1px solid #9599A6;
    z-index: -1;
    width: 80%;
    bottom: -21px;
}

.tutorial-items{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.tutorial-item{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.circle {
    display: -webkit-flex;
    display: flex;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #9599A6;
    color: #FFFFFF;
    z-index: 0;
    margin-left: -2px;
    font-size: 12px;
    cursor: pointer;
}

.circle.active{
    background-color: #E00000;
}

.circle:last-of-type {
    margin-right: 0;
}

.circle:last-of-type:after {
    display: none;
}

.tutorial-caption {
    margin-top: 8px;
    min-width: 80px;
    margin-right: 60px;
    font-size: 11px;
    color: #343740;
    line-height: 1.1;
    text-align: center;
}

.tutorial-caption:last-of-type {
    margin-right: 0;
}

.redLine{
    width: 100%;
    border: 2px solid #e00000;
    border-radius: 2px;
    margin-bottom: 10px;
}

.createFormGroup{
    margin: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

#customerDetails .createInspectionBody h4{
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #262626;
}

#customerDetails .createFormGroup input{
    min-width: 300px;
    height: 50px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
}

#customerDetails .createFormGroup label{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
}

#customerDetails .flag-container{
    padding: 0;
}

#customerDetails .selected-flag{
    border-radius: 6px;
    outline: none;
    background: #FFFFFF;
}

#customerDetails .selected-flag:hover{
    background: #dfe2e6;
}

#customerDetails .iti-arrow{
    right: 8px;
}

.checkForm label{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
    margin: 10px;
}

.checkForm input{
    margin-right: 5px;
    width: 18px;
    height: 18px;
}

.selected-flag{
    background-color:rgba(0,0,0,.05)
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text] {
    padding-left: 105px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
    width: 95px;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    background-color: rgba(213, 215, 219, 0.78)!important;
    display: table;
}
#vehicleDetails input, #vehicleDetails select{
    height: 50px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
}

#vehicleDetails label{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
}

#vehicleDetails .fileUploader {
    width: auto;
}

.downloadForm{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #EAEEF3;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-bottom: 10px;
}

.downloadForm .upload-vin button{
    width: 109px;
    height: 50px;
    font-size: 14px;
    text-align: center;
    color: #fff;
    white-space: nowrap;
    border-radius: 8px;
    background: #e00000;
    box-shadow: 0 12px 16px rgba(69, 91, 99, 0.1);
}

.downloadForm .createFormGroup:first-child{
    box-sizing: content-box;
    width: 55%;
    min-width: 250px;
}

.downloadForm .createFormGroup:last-child{
    box-sizing: border-box;
    width: 38%;
    min-width: 250px;
}

.fileContainer .chooseFileButton{
    padding: 0;
}

.fileContainer .downloadPhotoButton{
    box-sizing: content-box;
    box-sizing: initial;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-width: 40px;
    min-height: 40px;
    background-color: #E00000;
    border-radius: 50%;
    margin: 0 0 0 10px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.downloadPhotoButton:hover{
    background-color: #c90000;
}

.gridForm{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 25px;
    min-height: 200px;
}

.formShort{
    box-sizing: border-box;
    width: 20%;
    min-width: 120px;
    padding: 0 5px;
}

.formLong{
    box-sizing: border-box;
    width: 40%;
    min-width: 250px;
    padding: 0 5px;
}

.drug-and-drop-input{
    height: 48px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
    display: -webkit-flex;
    display: flex;
    width: 100%;
    color: #858585;
    border: 1px dashed #858585;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 13px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.drug-and-drop-input:hover{
    cursor: copy;
}

.loader-wrapper {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
}


#inspectionDetails .createInspectionBody label{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
    white-space: nowrap;
    margin-right: 10px;
}

#inspectionDetails .inspectionType{
    width: 150px;
    margin: 0 10px 0 0;
}

#inspectionDetails .inspectionType:hover{
    cursor: pointer;
    background-color: #023758;
}

#inspectionDetails .inspectionType.active{
    background-color: #DC0000;
}

#inspectionDetails .inspectionType p{
    margin: 0;
    padding: 0;
}

#inspectionDetails .inspectionType svg{
    margin-right: 5px;
}

#inspectionDetails .createFormGroup{
    margin: 0 10px 10px 0;
}

#inspectionDetails label{
    min-width: 106px;
}

#inspectionDetails select{
    width: 300px;
}

.greyLine{
    width: 100%;
    background: transparent;
    border: 1px solid #EAEEF3;
    margin: 10px 0;
}

.react-datepicker{
    border: none;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    width: 300px;
    height: 280px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.react-datepicker__month-container{
    height: 100%;
}

.react-datepicker__current-month{
    font-weight: bold;
    font-size: 15px;
    margin-top: 4px;
    color: #454f63;
    text-transform: uppercase;
}

.react-datepicker__day-names{
    margin-top: 10px;
}

.react-datepicker__day-name{
    color: #b9b9b9;
    font-weight: normal;
    font-size: 15px;
}

.react-datepicker__header{
    height: 65px;
}

.react-datepicker__navigation{
    top: 15px;
    outline: none;
}

.react-datepicker__header{
    background: transparent;
    border-radius: unset;
    border: none;
}

.react-datepicker__day{
    border-radius: 50%;
}

.react-datepicker__day:hover{
    border-radius: 50%;
}

.react-datepicker__day--selected{
     background: #dc0000;
}

.react-datepicker__day--selected:hover{
    background: #d30000;
}


.react-datepicker__day--outside-month{
    visibility: hidden;
}

.inspectionSchedule{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 300px;
    height: 280px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    padding: 15px;
}

.inspectionSchedule p{
    height: 16px;
    background: transparent;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: #78849e;
    margin: 0;
}

.inspectionSchedule p span{
    display: inline-block;
    width: 30px;
    text-align: right;
}

#inspectionDetails .inspectionTypeWrapper label{
    width: 234px;
}

#inspectionDetails .react-datepicker{
    margin: 0 15px 15px 0;
}

.test > div > div {
    position: relative !important;
}
.dotLine{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 7px;
}

.bigDot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #78849e;
}

.littleDot{
    margin-top: 3px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: rgba(120, 132, 158, 0.29);
}
footer{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    z-index: 99;
    background: #f5f6f7;
    height: 110px;
}

footer div{
    margin: 25px auto;
    padding-top: 15px;
    width: 75%;
    background: transparent;
    border-top: 5px solid #e2e2e2;
}

footer div p{
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #9E9E9E;
    margin: 0;
}

footer div p span{
    color: #E00000;
}
#admin {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;

  background-color: #f5f6f7;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.admin-header {
  display: -webkit-flex;
  display: flex;
  padding: 25px 25px 10px 25px;
  width: 100%;
  background-color: #ffffff;
}

.admin-header h3 {
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #262626;
}

.admin-header p {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #59626a;
}

.admin-header svg {
  margin-right: 10px;
}

#admin .admin-nav {
  display: -webkit-flex;
  display: flex;
  padding: 0 25px 25px 25px;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #ffffff;
}

#admin .admin-nav button {
  margin: 0 10px;
}

#admin .admin-body {
  margin: 25px;
}

#admin .admin-body .option-button {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: none;
  border: none;
  outline: none;
  height: 38px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

#admin .admin-body .option-button:disabled i {
  color: #c9c9c9;
}

#admin .admin-body .option-button:disabled:hover i {
  color: #c9c9c9;
}

#admin .admin-body .option-button i {
  color: #454f63;
}

#admin .admin-body .option-button:hover {
  cursor: pointer;
}

#admin .admin-body .option-button:hover i {
  color: #e00000;
}

#admin .admin-body .autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
  display: block;
}

#admin .admin-body .autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
}
#admin .admin-body .autocomplete-dropdown-container:before {
  content: "";
}

#admin .admin-body .autocomplete-dropdown-container .suggestion-item {
  padding: 2px 10px;
}
#admin .admin-body .autocomplete-dropdown-container .suggestion-item--active {
  padding: 2px 10px;
}

.users-table{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.users-table .new-user-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    width: 100%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.users-table .new-user-wrapper .new-user-buttons{
    margin: 0 0 25px 0;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.add-user-form{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 25px;
    margin-bottom: 25px;
}

.add-user-form > div{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.add-user-form div.add-user-custom-size{
    width: 32.5%;
}

.users-table .new-user-wrapper .error-message{
    width: 100%;
    text-align: center;
    color: #E00000;
    margin: 0;
    font-size: 13px;
}
.locations-table{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.locations-table .locations-table-wrapper{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
}

.locations-table .locations-table-wrapper > div:first-of-type{
    height: 200px;
    margin-bottom: 15px;
}

.map-hide{
    display: none;
    margin: 0 !important;
    height:0 !important;
    opacity: 0;
    overflow: hidden;
}

.nickname-wrapper{
    /*padding-top: 48px*/
}
.fault-codes-table{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.fault-codes-table.c-bordered{
    border: 1px solid #4b5156;
}
.fault-codes-table .material-icons{cursor: pointer;}
.fault-codes-table .createFormGroup{
    margin: 15px 0;
    width: 49%;
}

.icon-styles{
  -webkit-flex-direction: 'row';
          flex-direction: 'row';
}
.banks-table .new-bank-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.banks-table .new-bank-wrapper .new-user-buttons {
  margin: 0 0 25px 0;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.add-user-form {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 25px;
}

.add-user-form > div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.add-user-form div.add-user-custom-size {
  width: 32.5%;
}

.users-table .new-user-wrapper .error-message {
  width: 100%;
  text-align: center;
  color: #e00000;
  margin: 0;
  font-size: 13px;
}

